import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Paragraph from "../../../../../components/paragraph"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const data = useStaticQuery(graphql`
    query VersgrenzenQuery {
      poster: file(
        relativePath: { eq: "kurse/poster-lierau-enjambements.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/satzbau" />
      }
      navigation={navigation}
    >
      <Seo title="Versgrenzen setzen" />
      <Stack>
        <Heading as="h2" level={2}>
          Versgrenzen setzen
        </Heading>
        <Paragraph>
          In der 1. Strophe wird das Bild vom Land, das in den See »hänget«,
          sowie jenes der Schwäne, die ihr Haupt ins Wasser »tunken«, durch die
          Enjambements noch verstärkt: Die Sätze beugen sich von einer in die
          nächste Zeile und verbinden die Verse miteinander.
        </Paragraph>
        <Paragraph>
          In der 2. Strophe entsteht durch die abrupten Zeilenabbrüche dagegen
          ein Irritationsmoment – eine Pause, die den Satz- und Lesefluss
          unterbricht und auf einen sprachlosen Stillstand am Ende des Gedichts
          hinausläuft.
        </Paragraph>
        <Paragraph>
          Mehr über Hölderlins Spiel mit Versgrenzen und Enjambements erfährst
          du in diesem Video mit unserer Ausstellungsvermittlerin Sylvia Lierau…
        </Paragraph>
        <VideoPlayer src="kurse/lierau-enjambements" poster={data.poster} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
